<!-- src/layouts/MainLayout.vue -->
<template>
  <div class="contact-bar">
    <div class="contact-info d-flex justify-content-center align-items-center">
      <div class="email">
        <img class="mx-1 mx-sm-2" src="/assets/img/envelope.svg" />
        <a href="mailto:info@visitwicklow.ie" class="contact-link"
          >info@visitwicklow.ie</a
        >
      </div>
      <!-- <div class="separator"></div>
            <div class="phone">
                <img class="mx-1 mx-sm-2" src="/assets/img/phone.svg" />
                <a href="tel:+35340420100" class="contact-link">+353 40 420100</a>
            </div>-->
    </div>
  </div>

  <section class="header-section">
    <div class="container mobile">
      <div class="row align-items-start justify-content-between">
        <!-- Left: Menu Burger and Home Icon -->
        <div class="col-4 d-flex align-items-start">
          <!--<img src="/assets/img/logo.svg" alt="Logo" class="logo-img mobile">-->
          <!-- Menu Burger -->
          <button
            class="navbar-toggler mx-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarMenu"
            aria-controls="navbarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <!-- Home Icon -->
          <router-link to="/" class="nav-item nav-link"
            ><img src="/assets/img/home.svg" alt="Home" class="home-icon"
          /></router-link>
        </div>

        <div class="col-4 d-flex flex-column align-items-center text-center">
          <img src="/assets/img/logo.svg" alt="Logo" class="logo-img" />
        </div>

        <!-- Right: Search Field and Social Media Icons -->
        <div class="col-4 d-flex flex-column align-items-right align-items-end">
          <!-- Search Field -->
          <form
            class="d-flex align-items-start search-form mb-2"
            @submit.prevent="handleSearch"
          >
            <input
              class="form-control search-field me-2"
              type="search"
              v-model="headerSearchQuery"
              placeholder="Search.."
              aria-label="Search"
            />
            <button class="btn" type="submit">
              <img
                src="/assets/img/search.svg"
                alt="Search"
                class="search-icon"
              />
            </button>
          </form>
          <!-- Social Media Icons -->
          <div class="social-icons d-flex flex-wrap pt-2 align-items-right">
            <!-- Facebook -->
            <a v-if="header.Facebook" :href="header.Facebook" target="_blank">
              <img
                src="/assets/img/social-facebook.svg"
                alt="Facebook"
                class="social-icon"
              />
            </a>

            <!-- Instagram -->
            <a v-if="header.Instagram" :href="header.Instagram" target="_blank">
              <img
                src="/assets/img/social-instagram.svg"
                alt="Instagram"
                class="social-icon"
              />
            </a>

            <!-- Youtube -->
            <a v-if="header.Youtube" :href="header.Youtube" target="_blank">
              <img
                src="/assets/img/social-youtube.svg"
                alt="YouTube"
                class="social-icon"
              />
            </a>
            <!--
            <a href="#" target="_blank"
              ><img
                src="/assets/img/social-tiktok.svg"
                alt="TikTok"
                class="social-icon"
            /></a>
            -->

            <!-- Twitter -->
            <a v-if="header.Twitter" :href="header.Twitter" target="_blank">
              <img src="/assets/img/social-x.svg" alt="X" class="social-icon" />
            </a>
          </div>
        </div>
      </div>

      <!-- New row for the center logo -->
      <!--<div class="row desktop">
                <div class="col-12 text-center pt-3">
                    <img src="/assets/img/logo.svg" alt="Logo" class="logo-img">
                </div>
            </div>-->
    </div>

    <div class="container desktop">
      <div class="row align-items-start justify-content-between">
        <!-- Left: Menu Burger and Home Icon -->
        <div class="col-4 d-flex align-items-start">
          <!-- Menu Burger -->
          <button
            class="navbar-toggler mx-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarMenu"
            aria-controls="navbarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <!-- Home Icon -->
          <router-link to="/" class="nav-item nav-link"
            ><img src="/assets/img/home.svg" alt="Home" class="home-icon"
          /></router-link>
        </div>

        <!-- Center: Logo -->
        <div class="col-4 text-center pt-3">
          <img src="/assets/img/logo.svg" alt="Logo" class="logo-img" />
        </div>

        <!-- Right: Search Field and Social Media Icons -->
        <div class="col-4 d-flex flex-column align-items-right">
          <!-- Search Field -->
          <form
            class="d-flex align-items-start search-form mb-2"
            @submit.prevent="handleSearch"
          >
            <input
              class="form-control search-field me-2"
              type="search"
              v-model="headerSearchQuery"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn" type="submit">
              <img
                src="/assets/img/search.svg"
                alt="Search"
                class="search-icon"
              />
            </button>
          </form>
          <!-- Social Media Icons -->
          <!-- Social Media Icons -->
          <div class="social-icons d-flex flex-wrap pt-2">
            <!-- Facebook -->
            <a v-if="header.Facebook" :href="header.Facebook" target="_blank">
              <img
                src="/assets/img/social-facebook.svg"
                alt="Facebook"
                class="social-icon"
              />
            </a>

            <!-- Instagram -->
            <a v-if="header.Instagram" :href="header.Instagram" target="_blank">
              <img
                src="/assets/img/social-instagram.svg"
                alt="Instagram"
                class="social-icon"
              />
            </a>

            <!-- Youtube -->
            <a v-if="header.Youtube" :href="header.Youtube" target="_blank">
              <img
                src="/assets/img/social-youtube.svg"
                alt="YouTube"
                class="social-icon"
              />
            </a>
            <!--
            <a href="#" target="_blank"
              ><img
                src="/assets/img/social-tiktok.svg"
                alt="TikTok"
                class="social-icon"
            /></a>
            -->
            <!-- Twitter -->
            <a v-if="header.Twitter" :href="header.Twitter" target="_blank">
              <img src="/assets/img/social-x.svg" alt="X" class="social-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Bootstrap collapse for the menu -->
    <div class="collapse" id="navbarMenu">
      <div class="p-4">
        <button
          type="button"
          class="btn-close position-absolute top-0 end-0"
          aria-label="Close"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMenu"
        ></button>
        <ul class="navbar-nav" style="text-align: left">
          <li class="nav-item">
            <router-link to="/walks" class="" @click="closeMenu">
              <span class="menu-text">Trails</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/completedWalks" class="" @click="closeMenu">
              <span class="menu-text">Completed</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="full-width-image-section">
    <img
      src="/assets/img/Sep1.jpg"
      alt="Full Width Image"
      class="img-full-width"
    />
  </section>

  <section class="middle-menu-section">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <!-- Left: Trails and Completed Buttons -->
        <div class="col-auto d-flex align-items-center el-logos">
          <!-- Trails Button -->
          <router-link
            to="/walks"
            class="btn btn-link d-flex align-items-center"
          >
            <img
              src="/assets/img/menu-trails.svg"
              alt="Trails"
              class="me-2 menu-icon"
            />
            <span class="menu-text">Trails</span>
          </router-link>

          <router-link
            to="/completedWalks"
            class="btn btn-link d-flex align-items-center ms-3"
          >
            <img
              src="/assets/img/menu-completed.svg"
              alt="Completed"
              class="me-2 menu-icon"
            />
            <span class="menu-text">Completed</span>
          </router-link>
        </div>
        <!-- Right: Notifications Toggle Switch -->
        <div class="col-auto d-flex align-items-center el-notifications">
          <span class="menu-text me-1 me-sm-2">Notifications</span>
          <label class="switch">
            <input
              type="checkbox"
              v-model="notificationsEnabled"
              @change="toggleNotifications"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  </section>
  <button
    v-if="$route.name !== 'home'"
    type="button"
    class="btn btn-danger btn-floating btn-lg"
    id="btn-back"
    @click="goBack"
  >
    ←
  </button>
</template>

<script>
import { mapState } from "vuex";
import db from "@/db";
import { useRoute } from "vue-router";

export default {
  name: "Header",
  components: {},
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  created() {
    // Load the saved state from localStorage when the component is created
    const savedState = localStorage.getItem("notificationsEnabled");
    if (savedState !== null) {
      this.notificationsEnabled = JSON.parse(savedState);
    }
  },
  data() {
    return {
      tenantId: "", // Initial state
      header: {},
      notificationsEnabled: false,
      headerSearchQuery: "",
    };
  },
  computed: {
    ...mapState(["apiUrl", "customerId"]),
    isHomePage() {
      return this.$route.name === "home"; // Ensure 'home' is the name of your homepage route
    },
  },
  async mounted() {
    try {
      // Fetch General Header data
      this.tenantId = localStorage.getItem("tenantId");

      if (!this.tenantId) {
        throw new Error("Tenant ID not found in localStorage.");
      }

      var headerArray = await db.header
        .where("CustomerId")
        .equals(parseInt(this.tenantId))
        .toArray();

      if (headerArray.length > 0) {
        this.header = headerArray[0];
      } else {
        console.warn("No header data found for the given tenant ID.");
      }
    } catch (error) {
      console.error("Error fetching header data:", error);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
    handleSearch() {
      const currentRoute = this.$route.name;

      if (currentRoute === "pois") {
        const cardId = this.$route.params.CardId; // Get the current CardId from the route params

        if (cardId) {
          // Redirect to /pois/:CardId with the search query as a parameter
          this.$router.push({
            path: `/pois/${cardId}`,
            query: { search: this.headerSearchQuery },
          });
        } else {
          console.error("CardId is missing in the route params.");
        }
      } else if (currentRoute === "services") {
        const cardId = this.$route.params.CardId; // Get the current CardId from the route params

        if (cardId) {
          // Redirect to /pois/:CardId with the search query as a parameter
          this.$router.push({
            path: `/services/${cardId}`,
            query: { search: this.headerSearchQuery },
          });
        } else {
          console.error("CardId is missing in the route params.");
        }
      } else {
        // Default behavior: Redirect to the /walks page
        this.$router.push({
          path: "/walks",
          query: { search: this.headerSearchQuery },
        });
      }
    },
    closeMenu() {
      // Collapse the menu programmatically using Bootstrap's JavaScript API
      const navbarMenu = document.getElementById("navbarMenu");
      if (navbarMenu && navbarMenu.classList.contains("show")) {
        const collapseInstance = bootstrap.Collapse.getInstance(navbarMenu);
        if (collapseInstance) {
          collapseInstance.hide();
        }
      }
    },
    deleteDatabase() {
      const dbName = "AppDatabase";

      const request = indexedDB.deleteDatabase(dbName);

      request.onsuccess = () => {
        console.log("Database deleted successfully");
        this.redirectHome();
      };

      request.onerror = event => {
        console.error("Error deleting database:", event);
      };

      request.onblocked = () => {
        console.warn("Database deletion blocked");
      };
    },
    redirectHome() {
      this.$router.push({ name: "home" });
    },
    toggleNotifications() {
      // Save the state to localStorage whenever it changes
      localStorage.setItem(
        "notificationsEnabled",
        JSON.stringify(this.notificationsEnabled)
      );

      if (this.notificationsEnabled) {
        this.askNotificationPermission();
        console.log("Subscribed..");
      } else {
        this.unsubscribeUserFromPush();
        console.log("UnSubscribed..");
      }
    },
    askNotificationPermission() {
      if ("Notification" in window && "serviceWorker" in navigator) {
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            console.log("Notification permission granted.");
            this.subscribeUserToPush();
          } else {
            console.log("Notification permission denied.");
          }
        });
      } else {
        console.log("Notifications or Service Workers are not supported.");
      }
    },
    subscribeUserToPush() {
      const urlBase64ToUint8Array = base64String => {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding)
          .replace(/-/g, "+")
          .replace(/_/g, "/");
        const rawData = window.atob(base64);
        return new Uint8Array(
          rawData.split("").map(char => char.charCodeAt(0))
        );
      };

      navigator.serviceWorker.ready.then(registration => {
        const applicationServerKey = urlBase64ToUint8Array(
          "BF4idPaJxVZGQahiE16Jw77o9Yg-1f9kTNDB-nKcmTNuHyQx1dhEWPDRhXsktijRbFYAOPTJk8zX7Awtck5B9kc"
        );
        registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey,
          })
          .then(subscription => {
            console.log("User is subscribed:", subscription);
            console.log(
              "Subscription object keys:",
              subscription.toJSON().keys
            );
            console.log("Full subscription object:", subscription);

            // Manually construct the subscription object
            const pushSubscription = {
              endpoint: subscription.endpoint,
              expirationTime: subscription.expirationTime,
              keys: {
                p256dh: subscription.toJSON().keys.p256dh,
                auth: subscription.toJSON().keys.auth,
              },
            };

            // Send subscription to your server to store it
            this.sendSubscriptionToServer(pushSubscription);
          })
          .catch(error => {
            console.error("Failed to subscribe the user: ", error);
          });
      });
    },
    unsubscribeUserFromPush() {
      navigator.serviceWorker.ready.then(registration => {
        registration.pushManager
          .getSubscription()
          .then(subscription => {
            if (subscription) {
              const pushSubscription = {
                endpoint: subscription.endpoint,
                expirationTime: subscription.expirationTime,
                keys: {
                  p256dh: subscription.toJSON().keys.p256dh,
                  auth: subscription.toJSON().keys.auth,
                },
              };

              fetch(this.apiUrl + "unsubscribe", {
                method: "POST",
                body: JSON.stringify(pushSubscription),
                headers: {
                  "Content-Type": "application/json",
                  CustomerId: this.customerId,
                  Authorization: `Basic ${btoa("Ian:Ennistymon1!")}`,
                },
              })
                .then(response => {
                  if (!response.ok) {
                    throw new Error("Bad status code from server.");
                  }
                  return response.json();
                })
                .then(responseData => {
                  if (!(responseData && responseData.success)) {
                    throw new Error("Bad response from server.");
                  }
                  console.log(
                    "Successfully unsubscribed from push notifications."
                  );
                })
                .catch(error => {
                  console.error("Error unsubscribing:", error);
                });

              subscription
                .unsubscribe()
                .then(() => {
                  console.log(
                    "Successfully unsubscribed from push notifications."
                  );
                })
                .catch(error => {
                  console.error(
                    "Failed to unsubscribe from push notifications:",
                    error
                  );
                });
            } else {
              console.log("No existing subscription found.");
            }
          })
          .catch(error => {
            console.error("Error while getting subscription:", error);
          });
      });
    },
    sendSubscriptionToServer(subscription) {
      const base64Credentials = btoa("Ian:Ennistymon1!");

      console.log("Subscription object to be sent:", subscription);

      const subscriptionData = {
        Endpoint: subscription.endpoint,
        P256DH: subscription.keys.p256dh,
        Auth: subscription.keys.auth,
      };

      console.log("Subscription data to be sent:", subscriptionData);

      // Implement sending the subscription object to your server
      fetch(this.apiUrl + "subscribe", {
        method: "POST",
        body: JSON.stringify(subscriptionData),
        headers: {
          "Content-Type": "application/json",
          CustomerId: this.customerId,
          Authorization: `Basic ${base64Credentials}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Bad status code from server.");
          }
          return response.json();
        })
        .then(responseData => {
          if (!(responseData && responseData.success)) {
            throw new Error("Bad response from server.");
          }
        })
        .catch(error => {
          console.error("Error sending subscription to server:", error);
        });
    },
  },
};
</script>
<style scoped></style>
