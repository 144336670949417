<template>
  <section class="carousel slide">
    <div id="map" ref="mapContainer" style="height: 100vh; width: 100%"></div>
    <div
      id="follow-me-button"
      :class="{ following: isFollowing }"
      @click="toggleFollowMe"
    >
      <img
        v-show="isFollowing"
        width="30px"
        :src="`/img/location_crosshairs_icon_1.png`"
      />
      <img
        v-show="!isFollowing"
        width="30px"
        :src="`/img/location_crosshairs_icon_2.png`"
      />
    </div>
    <div v-if="isOffline" class="offline-notice">
      <p>You are in Offline Mode, Map zooming is limited.</p>
    </div>
    <div v-if="isPreparingOfflineTiles" class="offline-tile-prep">
      <p>The tiles are being prepared for offline use. Please wait...</p>
    </div>
    <div
      class="modal fade"
      id="noEmailModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Notification</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            You are not registered for Rewards for this walk.
            <br /><br />
            <button
              type="button"
              class="btn btn-primary"
              style="width: 300px"
              @click="redirectToRegister"
            >
              Register here
            </button>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="closeModal"
            >
              Continue unregistered
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showPermissionBanner" class="permission-banner">
      <p v-html="bannerMessage"></p>
    </div>
  </section>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker";
import arrowIcon from "@/assets/arrow.svg"; // Importing the SVG
import start from "@/assets/start.png";
import stop from "@/assets/stop.png";
import pinRed from "@/assets/location-dot-solid-Red.svg";
import pinBlue from "@/assets/location-dot-solid-Blue.svg";
import db from "@/db"; // Importing the Dexie database
import JSZip from "jszip";

export default {
  name: "MapComponent",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      marker: null,
      watchId: null,
      currentLocation: null,
      previousLocation: null,
      currentHeading: null,
      startMarker: null,
      endMarker: null,
      polyline: null,
      movingIcon: null,
      polylineData: [],
      poiData: [],
      StartLocationLat: null,
      StartLocationLng: null,
      EndLocationLat: null,
      EndLocationLng: null,
      isBadgeRegistered: false,
      isFollowing: true,
      baseUrl: process.env.VUE_APP_BASE_URL,
      showPermissionBanner: false,
      bannerMessage: "",
      onlineTiles: null,
      offlineTiles: null,
      isOffline: false,
      isPreparingOfflineTiles: false,
    };
  },
  created() {
    // Ensure isBadgeRegistered is false when the component is created
    this.isBadgeRegistered = false;
  },
  async mounted() {
    await this.initializeMap();
    await this.loadCardData();

    this.isBadgeRegistered = false;
    // Check if 'userEmail' is not in localStorage
    if (!localStorage.getItem("userEmail")) {
      // Trigger the Bootstrap modal
      this.showModal();
    }

    this.initializeTiles();

    this.checkAndSwitchLayer();

    window.addEventListener("online", this.checkAndSwitchLayer);
    window.addEventListener("offline", this.checkAndSwitchLayer);
  },
  beforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
    this.isBadgeRegistered = false;
  },
  methods: {
    redirectToRegister() {
      // Close the modal
      $("#noEmailModal").modal("hide");

      // Redirect after a slight delay to ensure the modal is closed
      setTimeout(() => {
        this.$router.push("/register/0");
      }, 1000); // 300ms should be enough time to ensure the modal has fully closed
    },
    showModal() {
      $("#noEmailModal").modal("show");
    },
    closeModal() {
      // Hide the modal manually using Bootstrap's API
      $("#noEmailModal").modal("hide");
    },

    async initializeMap() {
      this.$nextTick(() => {
        const mapElement = this.$refs.mapContainer;
        if (!mapElement) {
          console.error("Map container not found");
          return;
        }
        const initialCoords = [53, -9.09]; // Default to London, adjust as necessary

        // Initialize the map
        var mapOptions = {
          zoomAnimation: false,
          markerZoomAnimation: false,
        };
        this.map = L.map(mapElement, mapOptions).setView(
          [52.9146364, -6.2250095],
          15
        );

        // Add OpenStreetMap tiles
        // "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        this.onlineTiles = L.tileLayer(
          "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
          {
            attribution:
              '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          }
        ).addTo(this.map);

        this.offlineTiles = L.tileLayer("", {
          minZoom: 1,
          maxZoom: 16,
          maxNativeZoom: 13,
          tms: false,
          attribution: "OSM ",
        });

        // Initialize the offlinetiles
        this.initializeTiles();

        // Create the custom icon
        this.movingIcon = L.icon({
          iconUrl: arrowIcon,
          iconSize: [30, 30], // Adjust size as needed
          iconAnchor: [15, 15], // Adjust anchor to center the icon
        });

        // Create a marker with the custom icon and rotation
        this.marker = L.marker(initialCoords, {
          icon: this.movingIcon,
          rotationAngle: 0,
          rotationOrigin: "center",
        }).addTo(this.map);

        //FOLLOW ME ACTION
        this.map.on("dragend", () => {
          const followMeButton = L.DomUtil.get("follow-me-button");
          if (this.isFollowing) {
            this.isFollowing = false;
            followMeButton.innerHTML =
              followMeButton.innerHTML = `<img width="30px" src="/img/location_crosshairs_icon_2.png">`;
          }
        });

        // Start watching the user's location
        //this.startLocationWatch();
        this.startTracking();
      });
    },
    async loadCardData() {
      try {
        const card = await db.cards.get({ CardId: this.id }); // Adjust ID as necessary

        this.isBadgeRegistered = false;
        if (card) {
          //this.StartLocationLat = card.StartLocationLat;
          //this.StartLocationLng = card.StartLocationLng;
          //this.EndLocationLat = card.EndLocationLat;
          //this.EndLocationLng = card.EndLocationLng;

          this.polylineData = card.Polyline.map(point => [
            parseFloat(point.lat),
            parseFloat(point.lng),
          ]);
          this.poiData = card.POI || [];
          this.addPolylineAndMarkers();
          this.addPOIMarkers();
        }
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    },
    async registeredBadges() {
      if (!this.currentLocation) {
        console.error("Current location is not available.");
        return;
      }
      const userLat = this.currentLocation.lat;
      const userLng = this.currentLocation.lng;
      var test = this.polylineData[0][0];
      const distanceStart = this.calculateDistance(
        this.polylineData[0][0],
        this.polylineData[0][1],
        userLat,
        userLng
      );

      const lastIndex = this.polylineData.length - 1;
      const lastLat = this.polylineData[lastIndex][0];
      const lastLng = this.polylineData[lastIndex][1];
      const distanceEnd = this.calculateDistance(
        lastLat,
        lastLng,
        userLat,
        userLng
      );
      const within1km = distanceStart <= 2;
      const within1kmEnd = distanceEnd <= 2;

      const badgeData = {
        cardId: this.id,
        email: localStorage.getItem("userEmail"),
        start: true,
        start1km: within1km,
        end1km: within1kmEnd,
        longitude: this.currentLocation.lng,
        latitude: this.currentLocation.lat,
        timestamp: new Date().toISOString(),
      };

      try {
        await this.sendBadgeToAPI(badgeData);
        // /alert("Badge registered successfully!");
      } catch (error) {
        console.error("Error sending badge to API:", error);
        try {
          await db.badges.add(badgeData);
          this.registerBackgroundSync();
          alert("Badge saved locally and will be synced later.");
        } catch (dbError) {
          console.error("Error saving badge to IndexedDB:", dbError);
        }
      }

      //get users position
      //get distance from start lat lng
      // assemble data
      //data is like
      //id, cardId, email from localStorage, true, and true if user position is within 1km of the polyline start position
    },
    addPolylineAndMarkers() {
      if (this.polylineData.length > 0) {
        // Add polyline
        this.polyline = L.polyline(this.polylineData, { color: "blue" }).addTo(
          this.map
        );
        console.log(this.polylineData[0][0]);
        // Fit map bounds to polyline
        this.map.fitBounds(this.polyline.getBounds());

        // Add start marker
        //const startCoords = [this.StartLocationLat, this.StartLocationLng];
        const startCoords = [
          parseFloat(this.polylineData[0][0]),
          parseFloat(this.polylineData[0][1]),
        ];

        this.startMarker = L.marker(startCoords, {
          icon: L.icon({
            iconUrl: start, // Provide the correct path to your start icon
            iconSize: [30, 30], // Adjust size as needed
            iconAnchor: [15, 15], // Adjust anchor to center the icon
          }),
        }).addTo(this.map);

        // Add end marker
        //const endCoords = [this.EndLocationLat, this.EndLocationLng];
        const endCoords = [
          parseFloat(
            parseFloat(this.polylineData[this.polylineData.length - 1][0])
          ),
          parseFloat(
            parseFloat(this.polylineData[this.polylineData.length - 1][1])
          ),
        ];

        this.endMarker = L.marker(endCoords, {
          icon: L.icon({
            iconUrl: stop, // Provide the correct path to your end icon
            iconSize: [30, 30], // Adjust size as needed
            iconAnchor: [15, 15], // Adjust anchor to center the icon
          }),
        }).addTo(this.map);
      }
    },
    addPOIMarkers() {
      console.log(this.poiData);
      this.poiData.forEach(poi => {
        const coords = [parseFloat(poi.Latitude), parseFloat(poi.Longitude)];
        const iconUrl = poi.TypeId === "1" ? pinRed : pinBlue; // Different icons based on TypeId
        const theType = poi.TypeId === "1" ? "POI" : "SERVICE";
        const theColour = poi.TypeId === "1" ? "#ff0000" : "#0000ff";
        const icon = L.divIcon({
          html: `<img src="${iconUrl}" alt="Icon" style="width: 30px; height: 30px;">
                 <img src="${poi.Image}" alt="Thumbnail" style="width: 60px; height: 60px; position: absolute; top: -65px; left: -18px;border:3px solid white;">`,
          iconSize: [30, 30],
          className: "", // Remove default leaflet styling
        });

        L.marker(coords, { icon })
          .addTo(this.map)
          .bindPopup(
            `<h3 style="text-align:center;color:${theColour}">${theType}</h3><b><h5 style="text-align:center;">${poi.Title}</h5></b><br><img src="${poi.Image}" style="width:300px;"/><br><br>${poi.Body}`
          );
      });
    },
    updateBannerVisibility(permissionState) {
      if (permissionState === "denied") {
        this.showPermissionBanner = true; // Show banner if permission is denied
      } else {
        this.showPermissionBanner = false; // Hide banner if permission is granted or prompt
      }
    },
    startTracking() {
      console.log("Checking permissions and starting tracking...");

      const options = {
        maximumAge: 5000,
        enableHighAccuracy: true,
        timeout: 20000,
      };

      // Check for geolocation permission first
      // Main geolocation permission check with platform-specific logic
      navigator.permissions
        .query({ name: "geolocation" })
        .then(permissionStatus => {
          const platform = this.detectPlatform();
          console.log("Detected platform:", platform);

          if (platform === "ios") {
            // Always attempt to request geolocation directly on iOS and handle errors accordingly
            this.requestGeolocationTestPermissions(options, platform);
          } else {
            // For non-iOS platforms, handle permissions normally
            if (permissionStatus.state === "granted") {
              this.startLocationWatch(); // Start watching the position
            } else if (
              permissionStatus.state === "prompt" ||
              permissionStatus.state === "denied"
            ) {
              // Trigger geolocation request for prompt or denied state
              this.requestGeolocationTestPermissions(options, platform);
            }
          }

          permissionStatus.onchange = () => {
            console.log("Permission status changed:", permissionStatus.state);
            if (permissionStatus.state === "granted") {
              this.showPermissionBanner = false;
              this.startLocationWatch();
            } else if (permissionStatus.state === "denied") {
              this.showPermissionBanner = true;
              this.bannerMessage = this.getPermissionDeniedMessage(platform);
            }
          };
        })
        .catch(err => {
          console.error("Error querying permissions:", err);
          // Fallback if permissions query fails
          this.requestGeolocationTestPermissions(options, "fallback");
        });
    },
    requestGeolocationTestPermissions(options, platform) {
      // iOS doesn't support the Permissions API, so use getCurrentPosition directly
      if (platform === "ios") {
        navigator.geolocation.getCurrentPosition(
          position => {
            console.log("Geolocation successful:", position);
            this.startLocationWatch(); // Start watching position if successful
          },
          error => {
            console.error("Geolocation error:", error);
            this.handleGeolocationError(error, platform);
          },
          options
        );
      } else {
        // For other platforms, try to use the Permissions API for better user experience
        if (navigator.permissions) {
          navigator.permissions
            .query({ name: "geolocation" })
            .then(permissionStatus => {
              if (permissionStatus.state === "granted") {
                this.startGeolocationWatch(options);
              } else if (permissionStatus.state === "prompt") {
                // If permissions need to be prompted, request the current position
                navigator.geolocation.getCurrentPosition(
                  position => {
                    console.log("Geolocation successful:", position);
                    this.startLocationWatch();
                  },
                  error => {
                    console.error("Geolocation error:", error);
                    this.handleGeolocationError(error, platform);
                  },
                  options
                );
              } else if (permissionStatus.state === "denied") {
                // Show banner/message since permission is denied
                this.showPermissionBanner = true;
                this.bannerMessage = this.getPermissionDeniedMessage(platform);
              }
            })
            .catch(err => {
              console.error("Permissions API error:", err);
              // Fallback if Permissions API fails, especially for older browsers
              navigator.geolocation.getCurrentPosition(
                position => {
                  console.log("Geolocation successful:", position);
                  this.startLocationWatch();
                },
                error => {
                  console.error("Geolocation error:", error);
                  this.handleGeolocationError(error, platform);
                },
                options
              );
            });
        } else {
          // Fallback if Permissions API is not available
          navigator.geolocation.getCurrentPosition(
            position => {
              console.log("Geolocation successful:", position);
              this.startLocationWatch();
            },
            error => {
              console.error("Geolocation error:", error);
              this.handleGeolocationError(error, platform);
            },
            options
          );
        }
      }
    },

    handleGeolocationError(error, platform) {
      if (error.code === error.PERMISSION_DENIED) {
        this.showPermissionBanner = true;
        this.bannerMessage = this.getPermissionDeniedMessage(platform);
      } else if (error.code === error.POSITION_UNAVAILABLE) {
        this.showPermissionBanner = true;
        this.bannerMessage =
          "Location unavailable. Please check your device settings.";
      } else if (error.code === error.TIMEOUT) {
        alert("Location request timed out. Please try again.");
      } else {
        console.error("Unhandled geolocation error:", error);
      }
    },
    getPermissionDeniedMessage(platform) {
      if (platform === "ios") {
        return `
      <strong>Location Access Required</strong><br><br>
      Location access has been denied for this app on iOS.<br><br>
      To enable location services:<br>
      1. <strong>Check Site Permissions</strong>: In Safari, tap the "AA" icon in the address bar, then tap "Website Settings" and set "Location" to "Allow." <br>
      2. <strong>Check Safari Settings</strong>: Go to <strong>Settings > Safari > Location</strong> and select "While Using the App."<br><br>
      3. <strong>Check Safari Settings</strong>: Go to <strong>Settings > Privacy & Security > Location Services</strong> and enable location access.<br><br>
      After enabling, refresh the app to continue.
    `;
      } else if (platform === "android") {
        return `
      <strong>Location Access Required</strong><br><br>
      Location access has been denied for this app on Android.<br><br>
      To enable location services:<br>
      1. <strong>Check Site Permissions</strong>: Open your browser settings, go to "Site Settings" and make sure "Location" is set to "Allow." <br>
      2. <strong>Check System Settings</strong>: Go to <strong>Settings > Location</strong> on your phone and ensure location services are enabled for your browser.<br><br>
      After enabling, refresh the app to continue.
    `;
      } else if (platform === "windows") {
        return `
      <strong>Location Access Required</strong><br><br>
      Location access has been denied for this app on Windows.<br><br>
      To enable location services:<br>
      1. <strong>Check Browser Settings</strong>: Open your browser settings, go to "Site Settings" and make sure "Location" is set to "Allow." <br>
      2. <strong>Check System Settings</strong>: Go to <strong>Settings > Privacy > Location</strong> on your computer and enable location services.<br><br>
      After enabling, refresh the app to continue.
    `;
      } else {
        return `
      <strong>Location Access Required</strong><br><br>
      Location access has been denied for this app.<br><br>
      Please check your device and browser settings to ensure location services are enabled.<br><br>
      After enabling, refresh the app to continue.
    `;
      }
    },
    //TRACKING
    startLocationWatch() {
      if ("geolocation" in navigator) {
        this.watchId = navigator.geolocation.watchPosition(
          this.updateLocation,
          this.handleLocationError,
          { enableHighAccuracy: true }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
    updateLocation(position) {
      if (!position || !position.coords) {
        console.error("Position or coordinates are not available");
        return;
      }

      const { latitude, longitude } = position.coords;
      if (typeof latitude !== "number" || typeof longitude !== "number") {
        console.error("Invalid latitude or longitude values");
        return;
      }

      const newLatLng = new L.LatLng(latitude, longitude);

      if (this.previousLocation) {
        // Calculate bearing
        const heading = this.calculateBearing(this.previousLocation, newLatLng);
        console.log(`Calculated heading: ${heading}`);

        // Smooth the heading
        this.currentHeading = this.smoothHeading(this.currentHeading, heading);
        console.log(`Smoothed heading: ${this.currentHeading}`);

        // Ensure the marker is initialized before updating rotation
        if (this.marker) {
          this.marker.setRotationAngle(this.currentHeading);
        } else {
          console.error("Marker is not initialized");
        }
      }

      this.previousLocation = newLatLng;
      this.currentLocation = newLatLng;

      // Ensure the marker and map are initialized before interacting with them
      if (this.marker) {
        this.marker.setLatLng(newLatLng);
      } else {
        console.error("Marker is not initialized");
      }

      if (!this.map) {
        console.error("Map is not initialized");
      } else if (!this.isValidLatLng(newLatLng)) {
        console.error("newLatLng is not defined or invalid");
      } else {
        if (this.isFollowing) {
          this.map.panTo(newLatLng);
        }
      }
      console.log("enter Reg Badge");

      if (localStorage.getItem("userEmail") && !this.isBadgeRegistered) {
        // Check if polylineData is available
        if (this.polylineData && this.polylineData.length > 0) {
          // Registered for badges
          this.registeredBadges();
          this.isBadgeRegistered = true; // Ensure this part runs only once
        } else {
          console.log("Waiting for polylineData to be available...");
        }
      }

      // Ensure the map is initialized before setting the view
      if (this.isFollowing && this.map) {
        this.map.setView(newLatLng); // Use newLatLng instead of latlng
      } else if (!this.map) {
        console.error("Map is not initialized");
      }
    },

    handleLocationError(error) {
      console.error("Location error:", error);
      const options = {
        maximumAge: 5000,
        enableHighAccuracy: true,
        timeout: 20000,
      };

      // Fallback if permissions query fails
      this.requestGeolocationTestPermissions(options, "fallback");
    },
    detectPlatform() {
      const userAgent = navigator.userAgent.toLowerCase();

      // Check for iOS devices
      if (/iphone|ipad|ipod/.test(userAgent) && !window.MSStream) {
        return "ios";
      }

      // Check for Android
      if (/android/i.test(userAgent)) {
        return "android";
      }

      // Check for Windows
      if (/windows/i.test(userAgent)) {
        return "windows";
      }

      // Default to other/unknown
      return "unknown";
    },

    isValidLatLng(latlng) {
      return (
        latlng &&
        typeof latlng.lat === "number" &&
        typeof latlng.lng === "number" &&
        latlng.lat >= -90 &&
        latlng.lat <= 90 &&
        latlng.lng >= -180 &&
        latlng.lng <= 180
      );
    },
    toggleFollowMe() {
      const followMeButton = L.DomUtil.get("follow-me-button");
      this.isFollowing = !this.isFollowing;
      if (this.isFollowing) {
        followMeButton.innerHTML = `<img width="30px" src="/img/location_crosshairs_icon_1.png">`;
      } else {
        followMeButton.innerHTML = `<img width="30px" src="/img/location_crosshairs_icon_2.png">`;
      }

      if (this.isFollowing) {
        if (this.currentLocation) {
          this.map.setView(this.currentLocation);
        }
      }
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = (lat2 - lat1) * (Math.PI / 180);
      const dLon = (lon2 - lon1) * (Math.PI / 180);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
          Math.cos(lat2 * (Math.PI / 180)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in kilometers
      return distance;
    },
    calculateBearing(start, end) {
      const lat1 = start.lat * (Math.PI / 180);
      const lon1 = start.lng * (Math.PI / 180);
      const lat2 = end.lat * (Math.PI / 180);
      const lon2 = end.lng * (Math.PI / 180);

      const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
      const x =
        Math.cos(lat1) * Math.sin(lat2) -
        Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);
      const bearing = Math.atan2(y, x) * (180 / Math.PI);
      return (bearing + 360) % 360; // Normalize to 0-360 degrees
    },
    smoothHeading(previousHeading, newHeading) {
      if (previousHeading === null) return newHeading;
      const alpha = 0.2; // Smoothing factor, adjust as needed

      // Calculate the difference
      let delta = newHeading - previousHeading;

      // Ensure the shortest path is taken
      if (delta > 180) delta -= 360;
      if (delta < -180) delta += 360;

      const smoothedHeading = previousHeading + alpha * delta;
      return (smoothedHeading + 360) % 360; // Normalize to 0-360 degrees
    },
    async sendBadgeToAPI(badgeData) {
      const base64Credentials = btoa("Ian:Ennistymon1!");
      const baseURL = process.env.VUE_APP_API_BASE_URL;

      const response = await fetch(`${baseURL}VisitorCard/ReportBadge`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          CustomerId: localStorage.getItem("tenantId"), // Replace with actual CustomerId
          Authorization: `Basic ${base64Credentials}`,
        },
        body: JSON.stringify(badgeData),
      });

      if (!response.ok) {
        if (response.status === 409) {
          // Handle duplicate case
          //alert("It appears this badge has already been created.");
        } else {
          throw new Error("Failed to send badge to API");
        }
      }
    },
    async registerBackgroundSync() {
      if ("serviceWorker" in navigator && "SyncManager" in window) {
        try {
          const registration = await navigator.serviceWorker.ready;
          await registration.sync.register("sync-badges");
        } catch (error) {
          console.error("Error registering background sync:", error);
        }
      } else {
        console.warn("Background sync is not supported in this browser.");
      }
    },
    async initializeTiles() {
      const tileCount = await db.tiles.count();

      //Need to load tiles slowly or UI will suffer.
      if (tileCount > 0) {
        console.log("Loading tiles from IndexedDB...");
        this.isPreparingOfflineTiles = false;
        this.loadTilesInBatches(50, 50); // Load tiles progressively with slower processing
      } else {
        console.log("Fetching and extracting ZIP...");
        this.isPreparingOfflineTiles = true;
        await this.fetchAndSaveTiles();
        this.loadTilesInBatches(50, 50); // Load tiles progressively after saving
      }

      // Initialize Leaflet tile layer immediately (without waiting for all tiles)
      this.initializeOfflineTileLayer();
    },

    async loadTilesInBatches(batchSize = 50, delay = 50) {
      const tiles = await db.tiles.toArray();
      this.tileCache = {}; // Initialize an empty cache

      for (let i = 0; i < tiles.length; i += batchSize) {
        const batch = tiles.slice(i, i + batchSize);

        // Process the current batch
        batch.forEach(tile => {
          this.tileCache[tile.key] = URL.createObjectURL(tile.blob);
        });

        // Yield control to the UI thread and add a delay to slow down the processing
        await new Promise(resolve => setTimeout(resolve, delay));

        console.log(
          `Loaded batch ${i / batchSize + 1}/${Math.ceil(
            tiles.length / batchSize
          )}`
        );
      }

      console.log("All tiles loaded from IndexedDB.");
    },

    async fetchAndSaveTiles() {
      debugger;
      // Step 1: Check if the tiles table is already populated
      const existingTilesCount = await db.tiles.count(); // Get the number of records in the table
      const expectedTileCount = 762; // Replace with the actual number of tiles in your ZIP file

      if (existingTilesCount > expectedTileCount) {
        console.log("Tiles already inserted, skipping fetch and save.");
        this.isPreparingOfflineTiles = false;
        return;
      }

      // Step 2: Fetch the ZIP file from the cache or network
      const cache = await caches.open("zip-cache");
      const cachedResponse = await cache.match("/mapTiles/mapTiles.zip");

      const zipBlob = cachedResponse
        ? await cachedResponse.blob()
        : await fetch("/mapTiles/mapTiles.zip").then(res => res.blob());

      const zip = await JSZip.loadAsync(zipBlob);

      // Step 3: Extract and save tiles in batches
      const fileEntries = Object.keys(zip.files).filter(
        filename => !zip.files[filename].dir
      );

      const batchSize = 50; // Define the size of each batch
      for (let i = 0; i < fileEntries.length; i += batchSize) {
        const batch = fileEntries.slice(i, i + batchSize);

        // Process the current batch of tiles
        const batchPromises = batch.map(async filename => {
          const tileBlob = await zip.files[filename].async("blob");
          await db.tiles.put({ key: filename, blob: tileBlob });
        });

        // Wait for the current batch to finish before moving to the next
        await Promise.all(batchPromises);

        console.log(
          `Batch ${Math.ceil(i / batchSize) + 1}/${Math.ceil(
            fileEntries.length / batchSize
          )} saved.`
        );
      }
      this.isPreparingOfflineTiles = false;
      console.log("All tiles saved to IndexedDB.");
    },

    async loadTilesFromIndexedDB() {
      const tiles = await db.tiles.toArray();
      const tileCache = {};

      tiles.forEach(tile => {
        tileCache[tile.key] = URL.createObjectURL(tile.blob);
      });

      return tileCache;
    },

    initializeOfflineTileLayer() {
      this.tileCache = {}; // Local cache for quick synchronous access

      // Preload all tiles into this.tileCache
      db.tiles.toArray().then(tiles => {
        tiles.forEach(tile => {
          this.tileCache[tile.key] = URL.createObjectURL(tile.blob);
        });
        console.log("All tiles preloaded into cache");
      });

      this.offlineTiles.getTileUrl = ({ z, x, y }) => {
        const tileKey = `${z}/${x}/${y}.jpg`;
        console.log("Fetching tile:", tileKey);

        // Return synchronously from preloaded cache
        if (this.tileCache[tileKey]) {
          return this.tileCache[tileKey];
        } else {
          console.log("Tile not found in cache:", tileKey);
          return null; // Return an empty URL to prevent errors
        }
      };

      // Redraw the map to apply offline layer changes
      this.offlineTiles.redraw();
    },
    checkAndSwitchLayer() {
      if (navigator.onLine) {
        this.isOffline = false; // Online mode
        this.switchToOnline();
      } else {
        this.isOffline = true; // Offline mode
        this.switchToOffline();
      }
    },
    switchToOnline() {
      console.log("Switching to Online Mode...");
      if (this.map.hasLayer(this.offlineTiles)) {
        this.map.removeLayer(this.offlineTiles); // Remove offline tiles
      }

      if (!this.map.hasLayer(this.onlineTiles)) {
        this.map.addLayer(this.onlineTiles); // Add online tiles
        this.map.setMaxZoom(19); // Reset max zoom for online mode
        this.map.setMinZoom(1); // Allow full zoom range
      }

      console.log("Online layer activated.");
      this.failedTileCount = 0; // Reset tile failure counter if applicable
    },

    switchToOffline() {
      console.log("Switching to Offline Mode...");
      if (this.map.hasLayer(this.onlineTiles)) {
        this.map.removeLayer(this.onlineTiles); // Remove online tiles
      }

      if (!this.map.hasLayer(this.offlineTiles)) {
        this.map.addLayer(this.offlineTiles); // Add offline tiles
        this.map.setMaxZoom(13); // Limit max zoom for offline tiles
        this.map.setMinZoom(1); // Optional: Set min zoom to prevent errors
      }

      console.log("Offline layer activated.");
      this.checkVisibleTiles(); // Validate and log tile visibility if needed
    },
    checkVisibleTiles() {
      const bounds = this.map.getBounds();
      const zoom = this.map.getZoom();
      const tileSize = 256;

      const nw = this.map
        .project(bounds.getNorthWest(), zoom)
        .divideBy(tileSize)
        .floor();
      const se = this.map
        .project(bounds.getSouthEast(), zoom)
        .divideBy(tileSize)
        .floor();

      console.log(`Checking tiles for zoom level ${zoom}...`);

      for (let x = nw.x; x <= se.x; x++) {
        for (let y = nw.y; y <= se.y; y++) {
          const tileKey = `${zoom}/${x}/${y}.jpg`; // Adjust the format as needed
          const tileExists = !!this.tileCache[tileKey];
          console.log(`Tile: ${tileKey}, Exists: ${tileExists}`);
        }
      }
    },
  },
};
</script>

<style scoped>
#map {
  height: 100vh;
  width: 100%;
}

#follow-me-button {
  position: absolute;
  top: 20%;
  left: 8px;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#follow-me-button i {
  font-size: 24px;
}

#follow-me-button.following {
  background-color: #ffffff;
  color: white;
}
.permission-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8d7da; /* A light red background for error/warning messages */
  color: #721c24; /* Dark red text for readability */
  padding: 15px;
  border-top: 2px solid #f5c6cb; /* Slight border for separation */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-align: left; /* Align text to the left for easier reading */
  font-family: Arial, sans-serif;
  z-index: 1001;
  line-height: 1.3; /* Set a comfortable line-height for readability */
  font-size: 12px; /* Ensure text is legible on all devices */
}

.permission-banner p {
  margin: 0 0 10px; /* Ensure spacing between paragraphs */
}

.permission-banner strong {
  font-weight: bold; /* Bold important text */
}

.permission-banner ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0 0 10px;
}

.permission-banner li {
  margin: 5px 0;
}

.permission-banner a {
  color: #007bff; /* Make links a noticeable blue */
  text-decoration: none; /* Remove underlines from links */
}

.permission-banner a:hover {
  text-decoration: underline; /* Underline on hover for interactivity */
}

.permission-banner button {
  display: inline-block;
  background-color: #721c24; /* Use a matching dark red for the button */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px; /* Soft rounded corners for the button */
  font-size: 14px;
  cursor: pointer;
}

.permission-banner button:hover {
  background-color: #a94442; /* Slightly lighter red on hover */
}

.carousel {
  position: relative;
  top: -20px;
}

.offline-notice {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8d7da; /* Light red */
  color: #721c24; /* Dark red text */
  border: 1px solid #f5c6cb; /* Slightly darker red border */
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  z-index: 1000; /* Ensure it appears above the map */
}
</style>
