<template>
  <section class="full-width-image-section trails gray-bg">
    <div class="container">
      <div class="text-center">
        <h1 class="trails-header special-header">Amenities</h1>
      </div>

      <div class="trails-search">
        <!-- Sort Button -->
        <button @click="toggleSortDirection" class="btn btn-outline-primary">
          Sort by Distance
          <span v-if="sortDirection === 'asc'">↑</span>
          <span v-else>↓</span>
        </button>
      </div>

      <div class="trails-lists" style="margin-top: 50px">
        <div
          class="trails-list"
          v-for="poi in filteredPOIs"
          :key="poi.MarkerId"
        >
          <router-link
            :to="'/point/' + poi.MarkerId"
            class="col-md-6 mb-4 pb-2"
            style="text-decoration: none; color: inherit"
          >
            <figure>
              <ImageComponent
                v-if="poi.imageId"
                :imageId="poi.imageId"
              ></ImageComponent>
            </figure>

            <div class="trails-details">
              <h2 class="heading">{{ poi.Title }}</h2>
              <h5 class="sub-heading">
                {{ poi.DistanceFromCurrentLocation.toFixed(2) }} km
              </h5>
              <a href="#" class="btn">Read More</a>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <section class="signup-section pt-5 pb-5">
    <div class="container">
      <div class="row g-0">
        <!-- First Column: Image with Text -->
        <div
          class="col-md-6 d-flex flex-column justify-content-center signup-image-column text-white p-5 mt-5 mb-lg-5"
        >
          <h2 class="signup-header">Wicklow Trails</h2>
          <p class="signup-text">
            Sign up for our newsletter to stay updated on our walks, trails, and
            more!
          </p>
        </div>

        <!-- Second Column: Sign Up Form -->
        <div class="col-md-6 bg-white">
          <h2 class="signup-form-header text-white p-3 text-center">Sign Up</h2>
          <div class="px-5 pt-3 pb-5 pt-md-5">
            <form>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your name"
                />
              </div>
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Your email"
                />
              </div>
              <div class="mb-3">
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Message"
                ></textarea>
              </div>
              <button type="submit" class="btn w-100">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="full-width-image-section footer green-sep"></section>
</template>

<script>
import db from "@/db"; // Adjust based on your project structure
import ImageComponent from "@/components/ImageComponent.vue";

export default {
  name: "ServicesView",
  components: {
    ImageComponent,
  },
  props: {
    CardId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: "",
      pois: [],
      currentLat: null,
      currentLng: null,
      sortDirection: "asc",
    };
  },
  computed: {
    filteredPOIs() {
      let result = this.pois.map(poi => {
        const distance = this.getDistanceFromLatLonInKm(
          this.currentLat,
          this.currentLng,
          parseFloat(poi.Latitude),
          parseFloat(poi.Longitude)
        );
        return { ...poi, DistanceFromCurrentLocation: distance };
      });

      if (this.searchQuery) {
        result = result.filter(
          poi =>
            poi.Title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            poi.Body.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }

      // Sort by distance, respecting the current sort direction
      result.sort((a, b) => {
        const sortMultiplier = this.sortDirection === "asc" ? 1 : -1;
        return (
          (a.DistanceFromCurrentLocation - b.DistanceFromCurrentLocation) *
          sortMultiplier
        );
      });

      return result;
    },
  },
  methods: {
    toggleSortDirection() {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    },
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the earth in kilometers
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },

    async fetchPOIs() {
      try {
        const cardIdNumber = Number(this.CardId);
        if (isNaN(cardIdNumber)) {
          console.error("Invalid CardId:", this.CardId);
          return;
        }
        const card = await db.cards
          .where("CardId")
          .equals(cardIdNumber)
          .first();

        if (card && card.POI) {
          var test = card.POI;
          this.pois = card.POI.filter(poi => poi.TypeId === "2");
        } else {
          console.error("No POIs found for this CardId");
        }
      } catch (error) {
        console.error("Error fetching POIs:", error);
      }
    },
  },
  mounted() {
    navigator.geolocation.getCurrentPosition(
      position => {
        this.currentLat = position.coords.latitude;
        this.currentLng = position.coords.longitude;
        //this.fetchPOIs(); // Fetch POIs after getting current location
      },
      error => {
        console.error("Geolocation error:", error);
        // Still fetch POIs even if geolocation fails
      }
    );
    this.fetchPOIs();
    // Initialize searchQuery from the route's query parameters
    if (this.$route.query.search) {
      this.searchQuery = this.$route.query.search;
    }
  },
  watch: {
    "$route.params.CardId": {
      immediate: true,
      handler() {
        this.fetchPOIs();
      },
    },
    "$route.query.search"(newQuery) {
      this.searchQuery = newQuery || ""; // Update searchQuery dynamically
    },
  },
};
</script>

<style scoped>
.blog-item img {
  width: 540px;
  height: 360px;
  object-fit: cover; /* This will cover the area, cropping the image as needed. Use 'contain' to fit the whole image without cropping */
}
</style>
