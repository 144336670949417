<template>
  <section
    v-if="poiItem"
    id="carouselExampleControls"
    class="carousel slide"
    data-bs-ride="carousel"
    data-bs-touch="true"
  >
    <div class="carousel-inner">
      <!-- <div class="carousel-item active">
        <ImageComponentAnimate
          v-if="card.CoverImage"
          :imageId="card.CoverImage"
        ></ImageComponentAnimate>
      </div>-->

      <div class="carousel-item active">
        <ImageComponent
          v-if="poiItem.imageId"
          :imageId="poiItem.imageId"
          class="carimage"
          style="position: relative; width: 100%; height: auto"
        ></ImageComponent>
      </div>
    </div>
  </section>

  <section v-if="poiItem" class="full-width-image-section trail-info white-sep">
    <div class="container text-center"></div>
  </section>

  <section v-if="poiItem" class="trail-info-section">
    <div class="container text-center">
      <div class="trails-details">
        <h2 v-if="poiItem" class="heading">{{ poiItem.Title }}</h2>
        <h5 v-if="poiItem" class="sub-heading">{{ poiItem.SubTitle }}</h5>
        <p class="paragraph">
          {{ poiItem.Body }}
        </p>

        <h4 v-if="poiItem.Website" style="color: blue; margin-top: 50px">
          <a :href="poiItem.Website" target="_blank">Website</a>
        </h4>
        <h4 v-if="poiItem.DirectionsLink" style="color: blue">
          <a :href="poiItem.DirectionsLink" target="_blank">Directions</a>
        </h4>
      </div>
    </div>
  </section>

  <section class="signup-section pt-5 pb-5">
    <div class="container">
      <div class="row g-0">
        <!-- First Column: Image with Text -->
        <div
          class="col-md-6 d-flex flex-column justify-content-center signup-image-column text-white p-5 mt-5 mb-lg-5"
        >
          <h2 class="signup-header">Wicklow Trails</h2>
          <p class="signup-text">
            Sign up for our newsletter to stay updated on our walks, trails, and
            more!
          </p>
        </div>

        <!-- Second Column: Sign Up Form -->
        <div class="col-md-6 bg-white">
          <h2 class="signup-form-header text-white p-3 text-center">Sign Up</h2>
          <div class="px-5 pt-3 pb-5 pt-md-5">
            <form>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your name"
                />
              </div>
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Your email"
                />
              </div>
              <div class="mb-3">
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Message"
                ></textarea>
              </div>
              <button type="submit" class="btn w-100">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="full-width-image-section footer green-sep"></section>
</template>
<script>
import db from "@/db";
import LeafletMap from "@/components/LeafletMap.vue";
import ImageComponent from "@/components/ImageComponent.vue";

export default {
  components: {
    LeafletMap,
    ImageComponent,
  },
  data() {
    return {
      poiItem: { Image: "" },
    };
  },
  async mounted() {
    try {
      // Fetch card data based on the CardId parameter
      const Id = parseInt(this.$route.params.Id); // Convert to integer if necessary

      //only fetch the Gallery data rather than the whl;e card
      var poiData = await db.pois.where("MarkerId").equals(Id).toArray();

      this.poiItem = poiData[0];
    } catch (error) {
      console.error("Error fetching card data:", error);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Go back to the previous page
    },
  },
};
</script>
<style scoped>
@media (min-width: 1200px) {
  .carimage {
    top: -300px;
  }
}

@media (max-width: 1200px) {
  .carimage {
    top: 0px;
  }
}

@media (max-width: 992px) {
  .carimage {
    top: 0px;
  }
}

@media (max-width: 768px) {
  .carimage {
    top: 0px;
  }
}

@media (max-width: 576px) {
  .carimage {
    top: 0px;
  }
}
</style>
