<template>
  <img
    v-if="!asBackground && imageUrl"
    :src="imageUrl"
    class="img-fluid w-100 sunny-bright no-cache-image"
    alt=""
    @click="emitImageClicked"
  />
</template>

<script>
import db from "@/db";

export default {
  props: {
    imageId: {
      type: [Number, String],
      required: true,
    },
    asBackground: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageUrl: "",
    };
  },
  async mounted() {
    if (this.imageId) {
      await this.fetchAndSetImageUrl();
    } else {
      console.warn("imageId prop is undefined");
    }
  },
  methods: {
    async fetchImageUrl(imageId) {
      // Placeholder for your actual fetchImageUrl implementation
      if (!imageId) return "";
      try {
        const imageRecord = await db.images.get(imageId);
        return imageRecord ? URL.createObjectURL(imageRecord.blob) : "";
      } catch (error) {
        console.error("Failed to fetch image:", error);
        return "";
      }
    },
    async fetchAndSetImageUrl() {
      this.imageUrl = await this.fetchImageUrl(this.imageId);
      this.$emit("image-fetched", this.imageUrl); // Emit the fetched URL to the parent
    },
    async emitImageFetched() {
      this.imageUrl = await this.fetchImageUrl(this.imageId);
      this.$emit("clicked", this.imageUrl); // Emit the clicked URL to the parent
    },
  },
  beforeDestroy() {
    if (this.imageUrl) {
      URL.revokeObjectURL(this.imageUrl);
    }
  },
};
</script>
